@import 'styles/main';

.ca-sectionPopUp {
  width: 100%;
  background: $blue-land;
  display: inline-flex;
  padding: 40px;
  align-items: flex-start;
}
.flex-logo {
  flex: 0.2 1;
}
.flex-texte {
  flex: 1.8 1;
  margin-left: 15px;
}
.souligne-texte {
  text-decoration: underline;
}
.become-pro .buttons {
  max-width: 470px !important;
}

@include media-breakpoint-down(s) {
  .ca-sectionPopUp {
    flex-direction: column;
    text-align: center;
  }
  .flex-logo {
    flex: 1 1;
    margin: auto;
  }
  .flex-texte {
    flex: 1 1;
    margin-left: 0px;
    text-align: center;
  }
  .flex-texte :global(.text-left) {
    text-align: center !important;
  }
}
