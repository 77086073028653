@import 'styles/main';

.become-pro {
  background: $grey-cloud-ultralight;

  padding: $spacing-xl;
  & > *:not(ul) {
    margin-bottom: $spacing-basis;
  }
  .condensed {
    padding: $spacing-l;
    & > *:not(ul) {
      margin-bottom: $spacing-m;
    }
  }

  ul {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: $spacing-l;

    li {
      flex: 1 0 140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 140px;

      p {
        max-width: 100%;
      }
    }
  }

  :global(.buttons) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 430px;

    button {
      justify-content: center;

      > :last-child {
        margin-top: $spacing-m;
      }
    }
  }

  .specific-buttons {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 430px;
    justify-content: center;
  }
  .center {
    text-align: center;
  }
  .specific-padding :global(.basic-button) {
    padding: 10px;
    text-align: center;
  }
  @include media-breakpoint-down(s) {
    padding: $spacing-m;
    .specific-padding :global(.basic-button) {
      padding: 20px;
      text-align: center;
    }
    & > p:not(:first-child) {
      font-size: 16px !important;
      line-height: 26px !important;
    }

    ul {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: $spacing-m;

      li {
        flex-direction: row;
        max-width: none;
        flex: 1;
        margin-bottom: $spacing-s;

        svg {
          margin-right: $spacing-s;
        }

        p {
          text-align: left !important;
          font-size: 14px !important;
        }
      }
    }

    .buttons span {
      font-size: 16px;
    }
  }
}

.freemium-overlay {
  background: linear-gradient($grey-cloud-ultralight, 80%, transparent);
  position: absolute;
  height: 100%;
  z-index: 2;
}

.understood {
  font-weight: normal;
  margin-top: 24px;
  text-decoration: underline;
}
